<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <!-- Mautic -->
  <script type="text/javascript">
    /** This section is only needed once per page if manually copying **/
    if (typeof MauticSDKLoaded == 'undefined') {
      var MauticSDKLoaded = true;
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'http://mautic.mailshield.com.mx/media/js/mautic-form.js';
      script.onload = function () {
        MauticSDK.onLoad();
      };
      head.appendChild(script);
      var MauticDomain = 'http://mautic.mailshield.com.mx';
      var MauticLang = {
        'submittingMessage': "Please wait..."
      }
    }
  </script>


</head>

<body>

  <!-- Diapositiva 1 -->

  <section class="pagina_principal_1">
    <img class="logo_white" src="../assets/img/HS_Logo_white_3c_RZ.png" alt="logo_white">

    <div id="titulo_1">
      <h1> Mailshield: Seguridad En Su Correo Empresarial</h1>
    </div>

    <div id="descripcion_1">
      <p>Mailshield es la solución número uno en Latinoamérica para seguridad en correos corporativos para toda su
        organización.</p>
      <p>Ofrecemos protección contra spam y malwares en tus cuentas de correos alojadas en Microsoft 365, Gmail, y otros
        servicios de correo corporativo.
        Contamos con el encriptado, y backup de tus correos y archivos.
      </p>
    </div>

    <div class="hexagonos">
      <a class="hexagono">
        <span class="hexagono-content">
          <p>Detección de Spam y Malware</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-emoji-smile"></i>
          <p>Backup Automatizado</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Mecanismos IA detector de ataques</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Protección para Endpoints</p>
        </span>
      </a>
    </div>

    <button class="btn btn_demo">Solicita Una Demo</button>

  </section>

  <!-- Diapositiva 2 -->

  <section class="pagina_principal_2">

    <div id="titulo_2">
      <h2>Mailshield</h2>
    </div>

    <div id="descripcion_2">
      <p>
        México actualmete ocupa el primer lugar de intentos de ataques informáticos en Latinoamérica, contado con un 67%
        de ellos.
        Los ataques a la seguridad corporativa son constantes, y cada vez son más las empresas que están sufriendo ante
        la vulnerabilidad en su seguridad.
      </p>
      <p>
        Nuestra solución para cuentas de correo corporativas, brindan seguridad, constantes revisiones para eliminar
        amenazas y las opciones
        para acceder a toda tu información encriptada de froma rápida y segura.
      </p>
      <!-- <img class="hacker" src="../assets/img/hacker.jpg" alt="hacker"> -->
      <img class="logo_black" src="../assets/img/HS_Logo_black_3c_RZ.png" alt="logo_black">

    </div>



  </section>

  <!-- Diapositiva 3 -->

  <section class="pagina_principal_3">

    <div id="titulo_3">
      <h2>Característiscas de Mailshield</h2>
    </div>

    <div id="descripcion_3">
      <p>
        Nuetra solución en alianza con nuestro partner Hornet Segurity brinda una protección ideal para su organización
        en cuanto a seguridad
        y backup en el correo empresarial.
      </p>
    </div>

    <div class="hexagonos">
      <article class="hexagono">
        <span class="hexagono-content hexagono-hover ng-">
          <i class="bi bi-send"></i>
          <p id="hex_1" click="Modificar_texto()">Protección de Spam y Malware</p>
        </span>
      </article>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-emoji-smile"></i>
          <p>Encriptación de Correos</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Protección Ataques Forzdos</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Archivado de Correo en la Nube</p>
        </span>
      </a>
    </div>
    <div class="hexagonos">
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Continuidad de Correo ante Fallas</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Backup y Recuperaión de Datos</p>
        </span>
      </a>
      <a class="hexagono">
        <span class="hexagono-content">
          <i class="bi bi-heart"></i>
          <p>Firma Profesional y Legal de Correo</p>
        </span>
      </a>
    </div>

  </section>

  <!-- Diapositiva 4 -->

  <section class="pagina_principal_4">

    <div id="titulo_4">
      <h2>¿Por qué contar con seguridad en tu correo empresarial?</h2>
    </div>

    <div class="accordion accordion-item">
      <div class="accordion-item">
        <h2 class="accordion-header" id="acordeon_1">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapse_1" aria-expanded="false" aria-controls="collapse_1">
            <strong>+ Razones de auditoria y archivado</strong>
          </button>
        </h2>
        <div id="collapse_1" class="accordion-collapse collapse" aria-labelledby="acordeon_1">
          <div class="accordeon-body">
            <p>
              Los correos electrónicos de las empresas deben archivarse durante un determinado período y estar
              disponibles para que las autoridades componentes
              los inspeccionen en caso de investigaciones o auditorías.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="acordeon_2">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapse_2" aria-expanded="false" aria-controls="collapse_2">
            <strong>+ Evitar caidas del servidor</strong>
          </button>
        </h2>
        <div id="collapse_2" class="accordion-collapse collapse" aria-labelledby="acordeon_2">
          <div class="accordeon-body">
            <p>
              Ante fallos del sistemas o servidores pude afectar directamente al flujo de correos corporativos lo que
              conlleva a pérdidas.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="acordeon_3">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapse_3" aria-expanded="false" aria-controls="collapse_3">
            <strong>+ Evitar Vulnerabilidades</strong>
          </button>
        </h2>
        <div id="collapse_3" class="accordon-collapse collapse" aria-labelledby="acordeon_3">
          <div class="accordeon-body">
            <p>
              El spam es la forma más intrusiva para que los ciberdelicuentes introduzcan malware y virus en los
              sistemas informáticos de la empresas.
              A su vez, estp genera una enorme cantidad de correo no deseado para tu correo, por lo cual es más facil
              ser vulnerable a un ciber ataque por
              esta vía.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="acordeon_4">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapse_4" aria-expanded="false" aria-controls="collapse_4">
            <strong>+ Protección de ataques</strong>
          </button>
        </h2>
        <div id="collapse_4" class="accordion-collapse collapse" aria-labelledby="acordeon_4">
          <div class="accordeon-body">
            <p>
              Los ataques combinados utiliza diferentes tipos de malware simultáneamente. Virus, spayware, spam y
              phishing se combinan en un ataque
              por medio de correo electrónico. México es el epicentro de estos ataques con un 67% del total en
              Latinoamérica.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="acordeon_5">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapse_5" aria-expanded="false" aria-controls="collapse_5">
            <strong>+ Protección de información confidencial</strong>
          </button>
        </h2>
        <div id="collapse_5" class="accordion-collapse collapse" aria-labelledby="acordeon_5">
          <div class="accordeon-body">
            <p>
              Los correos corporativos contienen información valiosa y confidencial, que de ser interpretada, podría
              generar un gran peligro.
              Por ello la necesidad de una manera de encrytar dichos correos.
            </p>
          </div>
        </div>
      </div>
    </div>

  </section>

  <!-- Daiapositiva 5 -->

  <section class="pagina_principal_5">

    <div>
      <h2 id="titulo_5">Precios</h2>
    </div>

    <div class="row g-3">

      <ul class="list-group col-md-4">
        <li class="list-group-item titulo-lista">Total Protection For Business</li>
        <li class="list-group-item precio_lista">$2 <small id="subtitulo_5">/por cuenta al mes</small></li>
        <li class="list-group-item"><i class="bi bi-check"></i>Analisis en tiempo real del flujo de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Información de estado y encriptación</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Manejo informal</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control de contenido</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Funciones de filtro ampliadas y regulaciones de filtro
        </li>
        <li class="list-group-item"><i class="bi bi-check"></i>Protección de Spam y Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cree una lista negra y blanca con un solo clic</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control centralizado de las firmas</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Descargo de responsabilidad estandarizado y conforme a la
          leyaimer</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cifrado global SMIME / PGP</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Encriptacion forzada con la ayuda de Websafe</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Archivado de correo</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Archivado por 10 años de correo</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Acceso permanente al archivo de correo electronico
        </li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Busqueda de datos indexados</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Analisis Forense</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>ATP Sandboxing</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Control de malware de URL's</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Informes de amnezada a tiempo real</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Alertas de Malware</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Liminación de Malware</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Servicio de continuidad de correo electrónico</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Copias de seguridad automatizadas</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Recuperación granular de archivos o elementos de
          correo electrónico</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Copia de seguridad y recuperación de endpoints</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Auditoria de la actividad de las cuenras de copia de
          seguridad</li>
        <!-- <li class="list-group-item"><button class="btn btn-comprar">COMPRAR</button></li> -->
      </ul>

      <ul class="list-group col-md-4">
        <li class="list-group-item titulo-lista">Total Protection Enterprise</li>
        <li class="list-group-item precio_lista">$4 <small id="subtitulo_5">/por cuenta al mes</small></li>
        <li class="list-group-item"><i class="bi bi-check"></i>Analisis en tiempo real del flujo de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Información de estado y encriptación</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Manejo informal</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control de contenido</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Funciones de filtro ampliadas y regulaciones de filtro
        </li>
        <li class="list-group-item"><i class="bi bi-check"></i>Protección de Spam y Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cree una lista negra y blanca con un solo clic</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control centralizado de las firmas</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Descargo de responsabilidad estandarizado y conforme a la
          leyaimer</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cifrado global SMIME / PGP</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Encriptacion forzada con la ayuda de Websafe</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Archivado de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Archivado por 10 años de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Acceso permanente al archivo de correo electronico</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Busqueda de datos indexados</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Analisis Forense</li>
        <li class="list-group-item"><i class="bi bi-check"></i>ATP Sandboxing</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control de malware de URL's</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Informes de amnezada a tiempo real</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Alertas de Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Liminación de Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Servicio de continuidad de correo electrónico</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Copias de seguridad automatizadas</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Recuperación granular de archivos o elementos de
          correo electrónico</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Copia de seguridad y recuperación de endpoints</li>
        <li class="list-group-item"><i class="bi bi-x-circle"></i>Auditoria de la actividad de las cuenras de copia de
          seguridad</li>
        <!-- <li class="list-group-item"><button class="btn btn-comprar">COMPRAR</button></li> -->
      </ul>

      <ul class="list-group col-md-4">
        <li class="list-group-item titulo-lista">Total Protection For Enterprise</li>
        <li class="list-group-item precio_lista">$6 <small id="subtitulo_5">/por cuenta al mes</small></li>
        <li class="list-group-item"><i class="bi bi-check"></i>Analisis en tiempo real del flujo de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Información de estado y encriptación</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Manejo informal</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control de contenido</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Funciones de filtro ampliadas y regulaciones de filtro
        </li>
        <li class="list-group-item"><i class="bi bi-check"></i>Protección de Spam y Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cree una lista negra y blanca con un solo clic</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control centralizado de las firmas</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Descargo de responsabilidad estandarizado y conforme a la
          leyaimer</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Cifrado global SMIME / PGP</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Encriptacion forzada con la ayuda de Websafe</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Archivado de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Archivado por 10 años de correo</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Acceso permanente al archivo de correo electronico</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Busqueda de datos indexados</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Analisis Forense</li>
        <li class="list-group-item"><i class="bi bi-check"></i>ATP Sandboxing</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Control de malware de URL's</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Informes de amnezada a tiempo real</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Alertas de Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Liminación de Malware</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Servicio de continuidad de correo electrónico</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Copias de seguridad automatizadas</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Recuperación granular de archivos o elementos de correo
          electrónico</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Copia de seguridad y recuperación de endpoints</li>
        <li class="list-group-item"><i class="bi bi-check"></i>Auditoria de la actividad de las cuenras de copia de
          seguridad</li>
        <!-- <li class="list-group-item"><button class="btn btn-comprar">COMPRAR</button></li> -->
      </ul>

    </div>

  </section>

  <!-- Diapositiva 6 -->

  <section class="pagina_principal_6">

    <div>
      <h2 id="titulo_6">¿Por qué confiar en nosotros?</h2>
      <p id="descripcion_6">
        Somos parther certificado de Hornet Segurity para brindar servicios de seguridad. Contamos con un socio
        confiable y de calidad mundial
        que provee de servicios en todo el mundo.
      </p>
      <img class="hornetsecurity" src="../assets/img/Hornetsecurity_Partnerlogos_EXCELLENCE2019_EXCELLENCE_PARTNER.jpg"
        alt="hornetsecurity">
    </div>

  </section>

  <!-- Diapositiva 7 -->

 <!--  <section class="pagina_principal_7">

    <div>
      <h2 id="titulo_7">Noticias En Blog</h2>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="fondo">
            <img src="../assets/img/Ramsomware-1.jpg" alt="ramsomware" id="img-blog">

            <h3>¿Qué es el ransomware?</h3>

            <p id="info-blog">Cartagenndias fue, en el año 2007, ocasión de dongresos emparejados. En Medellíisetas con
              la iripción «Yo
              hablo español», y personas de toddad salían a su encuentro de los ranchitos identificáne con ellos en el
              patrimonio compartido: la lengua española.</p>

            <div id="bloque-desplegable" class="collapse">

              <p>Aquello no era más que el prólogo de Cartaga. Temíamoslos organizadores &mdash;el Institutrvantes, la
                Realademia y la Asociación de Academ que desposeyó del </p>

              <p>Muchas gracias.</p>

            </div>

            
            <a href="" id="leer-mas"> Leer más</a>

          </div>
        </div>

        <div class="col">
          <div class="fondo">
            <img src="" alt="" id="img-blog">
            <h3>¿Qué es el cryptomining?</h3>
            <p id="info-blog">Segunda de tres columnas</p>
            <a href="" id="leer-mas"> Leer más</a>
          </div>
        </div>

        <div class="col">
          <div class="fondo"><img src="../assets/img/Fraude CEO.jpg" alt="fraude-ceo" id="img-blog">
            <h3>¿Qué es fraude CEO?</h3>
            <p id="info-blog">Tercera de tres columnas</p>
            <a href="" id="leer-mas"> Leer más</a>
          </div>
        </div>

        <div class="col">
          <div class="fondo">
            <img src="../assets/img/SPAM.png" alt="spam" id="img-blog">
            <h3>¿Qué es SPAM?</h3>
            <p id="info-blog">Primera de tres columnas</p>
            <a href="" id="leer-mas"> Leer más</a>
          </div>

        </div>
        <div class="col">
          <div class="fondo">
            <img src="../assets/img/Malware.jpg" alt="malware" id="img-blog">
            <h3>¿Qué es un malware?</h3>
            <p id="info-blog">xxxxxx</p>
            <a href="" id="leer-mas"> Leer más</a>
          </div>
        </div>

        <div class="col">
          <div class="fondo">
            <img src="../assets/img/phishing.jpg" alt="phishing" id="img-blog">
            <h3>¿Qué es el phishing?</h3>
            <p id="info-blog">Tercera de tres columnas</p>
            <a href="" id="leer-mas"> Leer más</a>
          </div>

        </div>
      </div>
    </div>

  </section> -->

  <!-- Diapositiva 8 -->

  <section class="pagina_principal_8">

    <div>
      <h2 id="titulo_8">Contáctanos</h2>
    </div>

    <div class="formulario">

      <div id="mauticform_wrapper_contactosmailshied" class="mauticform_wrapper">
        <form autocomplete="false" role="form" method="post"
          action="http://mautic.mailshield.com.mx/form/submit?formId=3" id="mauticform_contactosmailshied"
          data-mautic-form="contactosmailshied" enctype="multipart/form-data">
          <div class="mauticform-error" id="mauticform_contactosmailshied_error"></div>
          <div class="mauticform-message" id="mauticform_contactosmailshied_message"></div>
          <div class="mauticform-innerform">


            <div class="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

              <div id="mauticform_contactosmailshied_f_name" data-validate="f_name" data-validation-type="text"
                class="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                <label id="mauticform_label_contactosmailshied_f_name" for="mauticform_input_contactosmailshied_f_name"
                  class="mauticform-label">Nombre</label>
                <input id="mauticform_input_contactosmailshied_f_name" name="mauticform[f_name]" value=""
                  placeholder="Nombre" class="mauticform-input" type="text">
                <span class="mauticform-errormsg" style="display: none;">Campo obligatorio</span>
              </div>

              <div id="mauticform_contactosmailshied_email1" data-validate="email1" data-validation-type="email"
                class="mauticform-row mauticform-email mauticform-field-2 mauticform-required">
                <label id="mauticform_label_contactosmailshied_email1" for="mauticform_input_contactosmailshied_email1"
                  class="mauticform-label">Correo electronico</label>
                <input id="mauticform_input_contactosmailshied_email1" name="mauticform[email1]" value=""
                  placeholder="Correo electronico" class="mauticform-input" type="email">
                <span class="mauticform-errormsg" style="display: none;">Campo obligatorio</span>
              </div>

              <div id="mauticform_contactosmailshied_phone" data-validate="phone" data-validation-type="tel"
                class="mauticform-row mauticform-tel mauticform-field-3 mauticform-required">
                <label id="mauticform_label_contactosmailshied_phone" for="mauticform_input_contactosmailshied_phone"
                  class="mauticform-label">Telefono</label>
                <input id="mauticform_input_contactosmailshied_phone" name="mauticform[phone]" value=""
                  class="mauticform-input" type="tel">
                <span class="mauticform-errormsg" style="display: none;">Campo obligatorio</span>
              </div>

              <div id="mauticform_contactosmailshied_f_message" data-validate="f_message"
                data-validation-type="textarea"
                class="mauticform-row mauticform-text mauticform-field-4 mauticform-required">
                <label id="mauticform_label_contactosmailshied_f_message"
                  for="mauticform_input_contactosmailshied_f_message" class="mauticform-label">Mensaje</label>
                <textarea id="mauticform_input_contactosmailshied_f_message" name="mauticform[f_message]"
                  class="mauticform-textarea"></textarea>
                <span class="mauticform-errormsg" style="display: none;">Campo obligatorio</span>
              </div>

              <div id="mauticform_contactosmailshied_submit"
                class="mauticform-row mauticform-button-wrapper mauticform-field-5">
                <button type="submit" name="mauticform[submit]" id="mauticform_input_contactosmailshied_submit" value=""
                  class="mauticform-button btn btn-default">Enviar</button>
              </div>
            </div>
          </div>

          <input type="hidden" name="mauticform[formId]" id="mauticform_contactosmailshied_id" value="3">
          <input type="hidden" name="mauticform[return]" id="mauticform_contactosmailshied_return" value="">
          <input type="hidden" name="mauticform[formName]" id="mauticform_contactosmailshied_name"
            value="contactosmailshied">

        </form>
      </div>

    </div>

    <!-- <div>
      <i class="bi bi-facebook"></i>
      <i class="bi bi-instagram"></i>
      <i class="bi bi-linkedin"></i>
    </div> -->

  </section>

</body>

</html>